.banner-notification {
  background: linear-gradient(0deg, rgba(187, 192, 215, 1) 25%, rgba(255, 255, 255, 1) 75%);
  color: #333;
  font-size: 1rem;
  padding: 1em;
  text-align: center;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-top: 3px solid #bbc0d7;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}

.banner-close-button {
  background: #6c82c9;
  border: none;
  padding: 0.5em 1em;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  z-index: 20;
}

.banner-close-button:hover {
  background: #4a5a9c;
  color: #fff;
}

.banner-notification-wrapper {
  position: relative;
  z-index: 10;
}

.banner-notification a {
  color: #6c82c9;
  text-decoration: underline;
  font-weight: bold;
}

.banner-notification a:hover {
  color: #4a5a9c;
  text-decoration: none;
}

.banner-notification ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.banner-notification li {
  margin-bottom: 0.5em;
}
